import { createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "row dashboard-card" }
const _hoisted_2 = {
  key: 0,
  class: "col-4 p-4"
}
const _hoisted_3 = { class: "mt-5 mb-5" }
const _hoisted_4 = {
  href: "",
  class: "text-primary fw-bold"
}
const _hoisted_5 = {
  key: 1,
  class: "col-4 p-4"
}
const _hoisted_6 = { class: "mt-5 mb-5" }
const _hoisted_7 = {
  href: "#",
  class: "text-primary fw-bold"
}
const _hoisted_8 = {
  key: 2,
  class: "col-4 p-4"
}
const _hoisted_9 = { class: "mt-5 mb-5" }
const _hoisted_10 = {
  href: "#",
  class: "text-primary fw-bold"
}
const _hoisted_11 = {
  key: 3,
  class: "col-4 p-4"
}
const _hoisted_12 = { class: "mt-5 mb-5" }
const _hoisted_13 = {
  href: "#",
  class: "text-primary fw-bold"
}
const _hoisted_14 = {
  key: 4,
  class: "col-4 p-4"
}
const _hoisted_15 = { class: "mt-5 mb-5" }
const _hoisted_16 = {
  href: "#",
  class: "text-primary fw-bold"
}
const _hoisted_17 = {
  key: 5,
  class: "col-4 p-4"
}
const _hoisted_18 = { class: "mt-5 mb-5" }
const _hoisted_19 = {
  href: "#",
  class: "text-primary fw-bold"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_el_card = _resolveComponent("el-card")!
  const _component_router_link = _resolveComponent("router-link")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (this.$store.getters.currentUser.user.userprofile.user_type.position === 1 || this.$store.getters.currentUser.services.indexOf('drive') !== -1)
      ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
          _createVNode(_component_router_link, { to: "/drive/" }, {
            default: _withCtx(() => [
              _createVNode(_component_el_card, { class: "bg-light-primary p-5 rounded-2 text-center dashboard-card" }, {
                default: _withCtx(() => [
                  _cache[0] || (_cache[0] = _createElementVNode("div", null, [
                    _createElementVNode("img", { src: "/media/menu/menu-drive.svg" })
                  ], -1)),
                  _createElementVNode("div", _hoisted_3, [
                    _createElementVNode("h2", null, [
                      _createElementVNode("a", _hoisted_4, _toDisplayString(_ctx.$t("pages.dashboard.drive")), 1)
                    ])
                  ])
                ]),
                _: 1
              })
            ]),
            _: 1
          })
        ]))
      : _createCommentVNode("", true),
    (this.$store.getters.currentUser.user.userprofile.user_type.position === 1 || this.$store.getters.currentUser.services.indexOf('attendance') !== -1)
      ? (_openBlock(), _createElementBlock("div", _hoisted_5, [
          _createVNode(_component_router_link, { to: "/attendance-monitoring/" }, {
            default: _withCtx(() => [
              _createVNode(_component_el_card, { class: "bg-light-primary p-5 rounded-2 text-center dashboard-card" }, {
                default: _withCtx(() => [
                  _cache[1] || (_cache[1] = _createElementVNode("div", null, [
                    _createElementVNode("img", { src: "/media/menu/menu-attendance.svg" })
                  ], -1)),
                  _createElementVNode("div", _hoisted_6, [
                    _createElementVNode("h2", null, [
                      _createElementVNode("a", _hoisted_7, _toDisplayString(_ctx.$t("pages.dashboard.attendanceMonitoring")), 1)
                    ])
                  ])
                ]),
                _: 1
              })
            ]),
            _: 1
          })
        ]))
      : _createCommentVNode("", true),
    (this.$store.getters.currentUser.user.userprofile.user_type.position === 1 || this.$store.getters.currentUser.services.indexOf('results') !== -1)
      ? (_openBlock(), _createElementBlock("div", _hoisted_8, [
          _createVNode(_component_router_link, { to: "/results/" }, {
            default: _withCtx(() => [
              _createVNode(_component_el_card, { class: "bg-light-primary p-5 rounded-2 text-center h-100" }, {
                default: _withCtx(() => [
                  _cache[2] || (_cache[2] = _createElementVNode("div", null, [
                    _createElementVNode("img", { src: "/media/menu/menu-results.svg" })
                  ], -1)),
                  _createElementVNode("div", _hoisted_9, [
                    _createElementVNode("h2", null, [
                      _createElementVNode("a", _hoisted_10, _toDisplayString(_ctx.$t("pages.dashboard.results")), 1)
                    ])
                  ])
                ]),
                _: 1
              })
            ]),
            _: 1
          })
        ]))
      : _createCommentVNode("", true),
    (this.$store.getters.currentUser.user.userprofile.user_type.position === 1 || this.$store.getters.currentUser.services.indexOf('roadmap') !== -1)
      ? (_openBlock(), _createElementBlock("div", _hoisted_11, [
          _createVNode(_component_router_link, { to: "/roadmap/" }, {
            default: _withCtx(() => [
              _createVNode(_component_el_card, { class: "bg-light-primary p-5 rounded-2 text-center h-100" }, {
                default: _withCtx(() => [
                  _cache[3] || (_cache[3] = _createElementVNode("div", null, [
                    _createElementVNode("img", {
                      class: "w-100",
                      src: "/media/menu/roadmap.svg"
                    })
                  ], -1)),
                  _createElementVNode("div", _hoisted_12, [
                    _createElementVNode("h2", null, [
                      _createElementVNode("a", _hoisted_13, _toDisplayString(_ctx.$t("pages.roadmap.title")), 1)
                    ])
                  ])
                ]),
                _: 1
              })
            ]),
            _: 1
          })
        ]))
      : _createCommentVNode("", true),
    (this.$store.getters.currentUser.user.userprofile.user_type.position === 1 || this.$store.getters.currentUser.services.indexOf('ideas_box') !== -1)
      ? (_openBlock(), _createElementBlock("div", _hoisted_14, [
          _createVNode(_component_router_link, { to: "/ideas/" }, {
            default: _withCtx(() => [
              _createVNode(_component_el_card, { class: "bg-light-primary p-5 rounded-2 text-center h-100" }, {
                default: _withCtx(() => [
                  _cache[4] || (_cache[4] = _createElementVNode("div", null, [
                    _createElementVNode("img", { src: "/media/menu/ideabox.svg" })
                  ], -1)),
                  _createElementVNode("div", _hoisted_15, [
                    _createElementVNode("h2", null, [
                      _createElementVNode("a", _hoisted_16, _toDisplayString(_ctx.$t("pages.ideas.title")), 1)
                    ])
                  ])
                ]),
                _: 1
              })
            ]),
            _: 1
          })
        ]))
      : _createCommentVNode("", true),
    (this.$store.getters.currentUser.user.userprofile.user_type.position === 1 || this.$store.getters.currentUser.services.indexOf('useful-training') !== -1)
      ? (_openBlock(), _createElementBlock("div", _hoisted_17, [
          _createVNode(_component_router_link, { to: "/useful-training/" }, {
            default: _withCtx(() => [
              _createVNode(_component_el_card, { class: "bg-light-primary p-5 rounded-2 text-center h-100" }, {
                default: _withCtx(() => [
                  _cache[5] || (_cache[5] = _createElementVNode("div", null, [
                    _createElementVNode("img", { src: "/media/menu/learning.svg" })
                  ], -1)),
                  _createElementVNode("div", _hoisted_18, [
                    _createElementVNode("h2", null, [
                      _createElementVNode("a", _hoisted_19, _toDisplayString(_ctx.$t("pages.usefulTraining.title")), 1)
                    ])
                  ])
                ]),
                _: 1
              })
            ]),
            _: 1
          })
        ]))
      : _createCommentVNode("", true)
  ]))
}